

import React from 'react'
import FooterBottom from '../molecules/FooterBottom'


const Footer = () => {
  return (
    <>
    <FooterBottom />
    </>
  )
}

export default Footer