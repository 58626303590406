import CardImg9 from '../../graphics/imagenes/productos/cincoj.png'
import CardImg10 from '../../graphics/imagenes/productos/capanegra.png'
import CardImg11 from '../../graphics/imagenes/productos/enriquetomas.png'
import CardImg12 from '../../graphics/imagenes/productos/montenevado.png'

const ImgCardImp = [

    //importados
    {
        id:"9",
        img: CardImg9,
        title: "5J",
    },
    {
        id:"10",
        img: CardImg10,
        title: "capa negra",
    },
    {
        id:"11",
        img: CardImg11,
        title: "enrique tomas",
    },
    {
        id:"12",
        img: CardImg12,
        title: "monte nevado",
    },

 ];

 export default ImgCardImp;
