import CardImg13 from '../../graphics/imagenes/productos/parmanacional/elartesano.png';
import CardImg14 from '../../graphics/imagenes/productos/parmanacional/laoctava.png';
import CardImg15 from '../../graphics/imagenes/productos/parmanacional/loscalvos.png';


const ImgCardParmaNac = [
    //tipo parma nacionales
    {
        id:"13",
        img: CardImg13,
        title: "el artesano",
        instalments: "tipo parma",
    },
    {
        id:"14",
        img: CardImg14,
        title: "la octava",
        instalments: "tipo parma",
    },
    {
        id:"15",
        img: CardImg15,
        title: "los calvos",
        instalments: "tipo parma",
    },
 ];

 export default ImgCardParmaNac;