import CardImg16 from '../../graphics/imagenes/productos/parmaimportado/parma.png';
import CardImg17 from '../../graphics/imagenes/productos/parmaimportado/ferrarini.png';
import CardImg18 from '../../graphics/imagenes/productos/parmaimportado/san michele.png';
import CardImg19 from '../../graphics/imagenes/productos/parmaimportado/negroni.png';


const ImgCardParmaImp = [
    //tipo parma importados
    {
        id:"16",
        img: CardImg16,
        title: "parma",
        instalments: "",
    },
    {
        id:"17",
        img: CardImg17,
        title: "ferrarini",
        instalments: "",
    },
    {
        id:"18",
        img: CardImg18,
        title: "san michele",
        instalments: "",
    },
    {
        id:"19",
        img: CardImg19,
        title: "negroni",
        instalments: "",
    },
 ];

 export default ImgCardParmaImp;