import CardImg20 from '../../graphics/imagenes/productos/box gourmet/Berlín copia.jpg';
import CardImg21 from '../../graphics/imagenes/productos/box gourmet/Bruselas copia.jpg';
import CardImg22 from '../../graphics/imagenes/productos/box gourmet/Camboriú copia.jpg';
import CardImg23 from '../../graphics/imagenes/productos/box gourmet/Lapampa copia.jpg';
//import CardImg24 from '../../graphics/imagenes/productos/box gourmet/Caja gourmet copia.jpg';
import CardImg25 from '../../graphics/imagenes/productos/box gourmet/Milan copia.jpg';
import CardImg26 from '../../graphics/imagenes/productos/box gourmet/París copia.jpg';
import CardImg27 from '../../graphics/imagenes/productos/box gourmet/caja zurich copia.jpg';
import CardImg28 from '../../graphics/imagenes/productos/box gourmet/Santafe copia.jpg';
import CardImg29 from '../../graphics/imagenes/productos/box gourmet/Papádulce copia.jpg';
import CardImg30 from '../../graphics/imagenes/productos/box gourmet/Papápicadamini1 copia.jpg';
import CardImg31 from '../../graphics/imagenes/productos/box gourmet/Papávip copia.jpg';
import CardImg32 from '../../graphics/imagenes/productos/box gourmet/Papácerveceros copia.jpg';
import CardImg33 from '../../graphics/imagenes/productos/box gourmet/Papácool copia.jpg';
import CardImg34 from '../../graphics/imagenes/productos/box gourmet/Caja 5j.jpg';
import CardImg35 from '../../graphics/imagenes/productos/box gourmet/Caja Torgelon o Los Calvos copia.jpg';
import CardImg36 from '../../graphics/imagenes/productos/box gourmet/Caja desayuno.jpg';


const ImgCardBox = [
    //BOX GOURMET
    {
        id:20,
        img: CardImg20,
    },
    {
        id:21,
        img: CardImg21,
    },
    {
        id:22,
        img: CardImg22,
    },
    {
        id:23,
        img: CardImg23,
    },
    //{
    //    id:"24",
    //    img: CardImg24,
    //},
    {
        id:25,
        img: CardImg25,
    },
    {
        id:26,
        img: CardImg26,
    },
    {
        id:27,
        img: CardImg27,
    },
    {
        id:28,
        img: CardImg28,
    },
    {
        id:29,
        img: CardImg29,
    },
    {
        id:30,
        img: CardImg30,
    },
    {
        id:31,
        img: CardImg31,
    },
    {
        id:32,
        img: CardImg32,
    },
    {
        id:33,
        img: CardImg33,
    },
    {
        id:34,
        img: CardImg34,
    },
    {
        id:35,
        img: CardImg35,
    },
    {
        id:36,
        img: CardImg36,
    },
 ];

 export default ImgCardBox;